// Here you can add other styles

$dark-clay : '#f2e7e0';

$color_green : #008f77;
$color_aghaz_blue : #3a5fac;
body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
.header {
    justify-content: flex-end;
    background-color: $color_green;
}
.sidebar {
    background-color: $color_green;
    overflow: hidden !important;
    overflow-y: hidden !important;
    object-fit: contain;
}
a {
    color: inherit!important;
    text-decoration: none!important;
    cursor: pointer;
}
.logo-nav {
    height: 20px;
    color: #fff;
}
.space-between {
    justify-content: space-between;
}
.offwhite-bg {
    background-color: #fdf9f7!important;
}

.bg-darker-clay {
    background-color: $dark-clay !important;
}
.bg-darker-green {
    background-color: $color_green !important;
}
.goal-deatils-box {
    border: 2px solid $color_green !important;
    border-radius: 5px !important;
    padding: (10px 15px 10px 15px) !important;
  }
  .offwhite-bg {
    background-color: #fdf9f7!important;
}
h1,.h1 {
    font-size: 2.5rem !important;
    font-family: "Heebo" !important;
    // line-height: 44px !important;
  }
  .Logo {
    //   background-image: url(../assets//brand/aghaz_logo.png);
    //   background-repeat: no-repeat;
      width: 120px;
      height: 30px;       
  }
  .login-Logo {
    //   background-image: url(../assets//brand/aghaz_logo.png);
    //   background-repeat: no-repeat;
      width: 120px;
      height: 25px;       
  }
  .side-logo {
    width: 400px;
   
  }
  .logo-below-text {
      text-align: left;
      font-family: "Heebo" !important;
      color: rgba(44, 56, 74, 0.681) !important;
  }
  .btn-two-container {
      justify-content: space-between;
      align-items: center;
  }
  .performance-box-containter {
      justify-content:center;
  }

  .performance-box {
    display: grid;
    min-height: 150px;
    min-width: 180px;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-radius: 0em;
    padding: 10px;
    // overflow: scroll;
    // color: #fdf9f7;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    align-items: center;
  }

 .goal-box-container {
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
 }

 .nav-custom-img-icon {
    height: 1.25rem;
 }
.sidebar-brand {
    display: flex;
    flex: 0 0 4rem;
    align-items: center;
    justify-content: center;
    color: var(--cui-sidebar-brand-color, rgba(255, 255, 255, 0.87));
    background: transparent !important;
}
.heading-bottom-line {
    background-image: url('https://d2j1npr7zw1pkf.cloudfront.net/aghaz-images/shariah/rectangle-green.png');
}
.form-control {
    border: 0px;
    border-bottom: 1px solid #cfcfcf !important;
    color: #3a5fa7;
    background-color: $dark-clay !important;
    width: 100%;
    height: 40px;
    border-radius: 0px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 0px !important;
}
.right-side-bar-heading {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 20px !important;
}
.goal-side-list {
    max-height: 250px;
    overflow: scroll;
}
.font-size-16 {
    font-size: 16px !important;
    font-weight: bold;
}
.font-size-14 {
    font-size: 14px !important;
    font-weight: bold;
}
.font-size-12 {
    font-size: 12px !important;
}
.divider-line {
    border: 1px solid #008f77;
    border-width: 2px;
}
.slick-slide {
    height: auto !important; // ← that must not be ignored
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
//   .slider-box {
//     min-height: 350px !important;
//   }
.form-control {
    background-color: transparent !important;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.text-align-center {
    text-align: center;
}

.small-tile-box {
  padding: 0px 10px !important;
  border-radius: 5px !important;
  margin: 0 0 10px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}
.statement-icon-up {
    color: #008f77;
}
.opt-container {
    justify-content: center;
}
.opt-box {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 10px !important;
    border: 2px solid  #008f77;
}
.modal-footer-one-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}
.otp-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem 0px 1rem 0px;
}
.center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25%;
  }
  .otp-modal-header {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    // border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.heading-box {
    display: grid;
    width: fit-content;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-radius: 0em;
    border: 1px solid #008f77;
    padding: 10px;
    // overflow: scroll;
    // color: #fdf9f7;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    align-items: center;
  }
  
.customMainNavTileDummy {
    height: 2px;
    background-color: $color_green;
    align-items: center;
    padding: 18px;
    margin-top: 10px;
    box-shadow: 0px 15px 10px -15px #111; 
    // box-shadow: 0 0px 5px 0px black;
}
.customMainNavTile {
    height: 100px;
    background-color: $color_green;
    align-items: center;
    padding: 25px;
    margin-top: 10px;
    box-shadow: 0px 15px 10px -15px #111; 
    // box-shadow: 0 0px 5px 0px black;
}
.customMainNavTile1 {
    height: 100px;
    background-color: $color_green;
    align-items: center;
    padding: 25px;
    margin-top: 10px;
    box-shadow: 0px 15px 10px -15px #111; 
    // box-shadow: 0px 6px 10px 0px black;
}
.customMainNavTile2 {
    height: 100px;
    background-color: $color_green;
    align-items: center;
    padding: 25px;
    margin-top: 10px;
    box-shadow: 0px 15px 10px -15px #111; 
    // box-shadow: 0px 6px 10px 0px blue;
}
.customMainNavTile3 {
    height: 100px;
    background-color: $color_green;
    align-items: center;
    padding: 25px;
    margin-top: 10px;
    box-shadow: 0px 15px 10px -15px #111; 
    // box-shadow: 0px 6px 10px 0px black;
}
.customMainNavTile:active {
    background-color:#008f77;
}

.customSubNavTile {
    height: 100px;
    background-color: $color_green;
    justify-content: center;
    align-items: center;
    // padding: 25px;
    // margin-top: 5px;
    border-left: 1px solid #111;
    display: grid;
    align-items: center;
    // box-shadow: 0px 15px 10px -15px #111; 
}
.border-bottom-nav {
    border-bottom: 1px solid #111 !important;
}

.side-bar-icon-size {
    height: 30px !important;
}
.box-bottom-shadow {
    box-shadow: 0px 15px 10px -15px #111; 
}
.custom-performance-tiles{
    height: 100px;
    // background-color: grey;
    background-color: $color_green;
    align-items: center;
    padding: 12px;
    margin-top: 10px;
    box-shadow: 5px  #888;
    box-shadow: 0px 15px 10px -15px #111; 
    font-size: 25px;
    border-radius: 5px;font-weight: bold;
    
}

.section.section-compare-goal .goal-tabs-wrapper .nav-tabs { 
    background-color: $color_green !important;
}

.table-row-background {
    background-color: whitesmoke !important;
}
.section.section-compare-goal .goal-tabs-wrapper .nav-tabs .nav-item .nav-link{ 
    height: 50px !important;
}
.section.section-compare-goal .goal-tabs-wrapper .nav-tabs .nav-item .nav-link.active { 
    height: 45px !important;
    background-color: $color_green !important;
}
.disabled_div {
    pointer-events: none !important;
}
.navbar-toggler-icon {
    background-image : url('https://d2j1npr7zw1pkf.cloudfront.net/aghaz-images/menu.png') !important;
} 
.custom-toggler {
        display: none;
}
.display-none {
    display: none !important;
}
.logout-side-bar {
    position: absolute;
    bottom: 10 !important;
    right: 10 !important;
}

@media (min-width: 768px) { 
    .riskProfileSelect {
        cursor: pointer;
        display: grid;
          max-width: 30%;
          height: 60px;
          background:white;
          margin-left: auto;
          margin-right: auto;
          align-items: center;
          margin-top: 10px;
      }
      .small-form-container {
        max-width: 40%;
        margin-left: auto;
        margin-right: auto;
     }
     .display-none-large {
        display: none !important;
    }
}
@media (max-width: 768px) { 
    .right-bar {
        display: none !important;
    }
   
}
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575.98px) {
    .custom-toggler {
        display: block;
    }
    .custom-manu {
        display: none;
    }
    .nav {
        display: flex !important;
        flex-wrap: wrap !important;
        padding-left: 0 !important;
        margin-bottom: 0 !important;
        list-style: none !important;
        flex-direction: column !important;
        align-content: space-around !important;
    }
    .riskProfileSelect {
        display: grid !important;
        height: 60px !important;
        background: white !important;
        margin-left: auto !important;
        margin-right: auto !important;
        align-items: center !important;
        margin-top: 10px !important;
    }
    .display-none {
        display: none !important;
    }
    .display-none-small {
        display: none !important;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .custom-toggler {
        display: block;
    }
    .custom-manu {
        display: none;
    }
    .display-none {
        display: none !important;
    }
}