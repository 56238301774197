/* Small devices (landscape phones, 576px and up) */
.login-background {
    background: url(../assets/images/regPageBG.jpg) no-repeat 0 0;
    background-size: cover;
    
}
.login-background-1 {
    background: url(../assets/backgrounds/login.png) no-repeat 0 0;
    opacity: 0.7;
}
.login-background-2 {
    background-color: #01a99d;
    // opacity: 0.7;
}

.dark-background {
    background-color: #4D4F5C !important;
  }
@media (max-width: 575.98px) {
   .login-page {
    width: 100% !important
   }
   .centered-col-item-small {
    display: grid !important;
    justify-content: center !important;
    align-content: center !important;
}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .login-page {
        width: 100% !important
       }
       .centered-col-item-small {
        display: grid !important;
        justify-content: center !important;
        align-content: center !important;
    }
    //    display: grid;
    // justify-content: center;
    // /* justify-content: center; */
    // align-content: center;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .login-page {
        width: 100% !important
       }
       .centered-col-item {
        display: grid !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
    .login-page {
        max-width: 50.666667% !important
       }
       .centered-col-item {
        display: grid !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

@media (min-width: 1200px) {
    .centered-col-item {
        display: grid !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .login-page {
        max-width: 50.666667% !important
       }
}